<div class="background">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <a [routerLink]="['/']">
        <svg-icon src="/assets/svg/logo.svg" [svgStyle]="{ 'width.px': 130 }"></svg-icon>
      </a>

      <mat-tab-group
        [selectedIndex]="activeTabIndex()"
        (selectedTabChange)="changeDatasource($event.index)"
        *ngIf="showTabBar()"
      >
        <mat-tab label="My Estimates"></mat-tab>
        <mat-tab label="All Estimates"></mat-tab>
        <mat-tab label="Archived"></mat-tab>
      </mat-tab-group>

      <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" *ngIf="user">
        <ul fxLayout="row" fxLayoutGap="10px">
          <img
            src="{{ user.picture }}"
            height="50px"
            alt="user_picture"
            class="profile-avatar"
            referrerpolicy="no-referrer"
          />
          <li style="padding-top: 13px">
            <p class="header-name">{{ user.name }}</p>
            {{ getUserInfo(user) }}
          </li>
          <li style="padding-top: 6px">
            <button mat-button [matMenuTriggerFor]="menu">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                Sign out
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <router-outlet></router-outlet>
</div>
