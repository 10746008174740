import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sale/estimates/my',
  },
  {
    path: 'sale',
    pathMatch: 'full',
    redirectTo: 'sale/estimates/my',
  },
  {
    path: 'sale',
    loadChildren: () => import('./sale/sale.module').then((m) => m.SaleModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sale-view/:id',
    loadChildren: () => import('./sale-view/sale-view.module').then((m) => m.SaleViewModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sale-edit/:id',
    loadChildren: () => import('./sale-edit/sale-edit.module').then((m) => m.SaleEditModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
