import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from './../environments/environment';
import { MatTabsModule } from '@angular/material/tabs';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { LoginComponent } from './login/login.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { ErrorComponent } from './error/error.component';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent, LoginComponent, ErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      domain: environment.auth0_domain,
      clientId: environment.auth0_clientId,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      audience: environment.api_url,
      errorPath: '/error',
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.api_url + '/*',
            tokenOptions: {
              audience: environment.api_url,
            },
          },
        ],
      },
    }),
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatCardModule,
    MatMenuModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    MatTabsModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
