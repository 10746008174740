import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  template: '',
})
export class LoginComponent {
  constructor(public auth: AuthService, public router: Router) {
    // If this component is loaded it means the user was denied entry.
    // Probably becaus he was not using an icapps.com email
    // We force a logout so the app is redirected to the auth0 login
    this.auth.logout();
  }
}
