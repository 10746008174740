import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  constructor(public auth: AuthService) {}

  public login() {
    this.auth.loginWithRedirect();
  }
}
