<div fxLayout="row" [ngStyle]="{ 'margin-top': '30px' }">
  <div fxFlex="50" fxFlexOffset="25">
    <mat-card>
      <mat-card-title> Oops </mat-card-title>
      <mat-card-content>
        <p>Something went wrong, please login again</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="login()">Login</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
