import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public userId: string;
  public user: any;
  public choiceChange: Subject<number> = new Subject<number>();
  public choiceFilter = 0;
  private readonly tabs = [
    {
      index: 0,
      value: 'my',
    },
    {
      index: 1,
      value: 'all',
    },
    {
      index: 2,
      value: 'archived',
    },
  ];

  constructor(public auth: AuthService, public router: Router) {
    this.auth.user$.pipe(take(1)).subscribe((user) => {
      this.user = user;
    });
  }

  public getUserInfo(user) {
    this.userId = user.sub;
  }

  public returnId() {
    return this.userId;
  }

  public changeDatasource(choice) {
    const tabValue = this.tabs.find((tab) => tab.index === choice).value;
    this.router.navigate(['/sale/estimates/' + tabValue]);
  }

  public logout(): void {
    this.auth.logout({ returnTo: window.location.origin });
  }

  public showTabBar(): boolean {
    return this.router.url.includes('/sale/');
  }

  // returns index for value in '/sale/estimates/{value}'
  public activeTabIndex(): number {
    return this.tabs.find((tab) => tab.value === this.router.url.substring(16)).index;
  }
}
